import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Components
import Layout from 'components/Layout'
import PageHeader from 'components/Sections/Page/PageHeader'
import PageContent from 'components/Sections/Page/PageContent'
// Models
import { IOptionalMetaProps } from 'models/SEO'
import { IPage } from 'models/Page'

type SongPageProps = {
    page: IPage
}

const SongPage: React.FC<PageProps<SongPageProps>> = ({ data }) => {
    const page = data.page
    const { title, thumbnail } = page
    const seo: IOptionalMetaProps = {
        title: title,
        description: `${title}`,
        image: thumbnail.file?.url,
    }

    return (
        <Layout seo={seo}>
            <PageHeader page={page} />
            <PageContent page={page} />
        </Layout>
    )
}

export default SongPage

export const pageQuery = graphql`
    query Page($slug: String!) {
        page: contentfulPages(slug: { eq: $slug }) {
            title
            slug
            content {
                raw
            }
            thumbnail {
                gatsbyImageData(layout:CONSTRAINED)
                file {
                    url
                }
            }
        }
    }
`
