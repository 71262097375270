import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
// Models
import { IPage } from 'models/Page'
// Styles
import * as styles from './PageHeader.module.css'

type PageHeaderProps = {
    page: IPage
}

const PageHeader: React.FC<PageHeaderProps> = ({ page }) => {
    const { title, thumbnail } = page
    return (
        <header className={styles.page_header}>
            <GatsbyImage
                className={styles.page_thumbnail}
                alt={title}
                image={thumbnail.gatsbyImageData}
            />
            <h1 className={styles.page_title}>{title}</h1>
        </header>
    )
}

export default PageHeader
