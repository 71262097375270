import React from 'react'
// Components
import Content from 'components/Layout/Content'
import DefaultSidebar from 'components/Layout/Sidebar/DefaultSidebar'
import RichText from 'components/UIElements/RichText'
import Share from 'components/UIElements/Share'
// Models
import { IPage } from 'models/Page'
// Styles
import * as styles from './PageContent.module.css'

type PageContentProps = {
    page: IPage
}

const PageContent: React.FC<PageContentProps> = ({ page }) => {
    const { title, content } = page
    return (
        <main className={styles.page}>
            <Content>
                <RichText content={content} alt={title} />
                <Share message={title} />
            </Content>
            <DefaultSidebar />
        </main>
    )
}

export default PageContent
